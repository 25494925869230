export enum Experiment {
  UseNewRoutesService = 'specs.membersArea.UseNewRoutesService',
  UseSausageBar = 'specs.membersAreaV2.EnableSausageBar',
  CleanUpBobWhenMAIsDeleted = 'specs.membersArea.CleanUpBobWhenMAIsDeleted',
  UseNewBlockedEmptyState = 'specs.profileCardOOI.useNewBlockedEmptyState',
  UseRoutesFromGlobalController = 'specs.membersArea.routesFromGlobalController',
  DoNotInitiallyInstallProfileCard = 'specs.membersArea.DoNotInitiallyInstallProfileCard',
  WaitForMemberPageTransaction = 'specs.membersArea.WaitForMemberPageTransaction',
  EnableGlobalControllerDataFixer = 'specs.membersAreaV2.EnableGlobalControllerDataFixer',
  DontWaitForBoBController = 'specs.membersArea.DontWaitForBoBController',
  UseSyncDeleteActions = 'specs.membersAreaV2.UseSyncDeleteActions',
  SyncDataWithMenuItems = 'specs.membersAreaV2.SyncDataWithMenuItems',
  RemoveUpdatingBoBController = 'specs.responsive-editor.NoMeasureInstall',
  UseAppDataForRoutes = 'specs.membersArea.UseAppDataForRoutes',
  DisableRouteIntegrityCheck = 'specs.membersArea.DisableRouteIntegrityCheck',
}
